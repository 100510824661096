<template>
  <MRow :gutter="0">
    <MCol>
      <FlotoFormItem
        id="name"
        v-model="formData.name"
        :label="$t('name')"
        auto-focus
        rules="required|subject"
        :placeholder="$t('name')"
      />
      <FlotoFormItem
        id="description"
        v-model="formData.description"
        :label="$t('description')"
        :rows="2"
        :placeholder="$t('description_instruction')"
        type="textarea"
        rules="required"
      />
      <FlotoFormItem
        id="content"
        v-model="formData.categoryPrefix"
        :label="`${$tc('category')} ${$tc('prefix')}`"
        class="category-prefix-style"
        :placeholder="`${$tc('category')} ${$tc('prefix')}`"
        :rules="validationRules"
      >
        <template v-slot:before-input>
          <KeywordSelector
            v-if="moduleName === $constants.SERVICE_CATALOG"
            ref="keywordPopup"
            :available-keywords="availableKeywords"
            :module-name="moduleName"
            placement="bottomRight"
            @selected="insertKeywordForCategoryPrefix"
            @hide="prefixKeywordSelectorHide"
          >
            <a class="keyword-trigger">
              <small> {{ $t('insert') }} {{ $tc('placeholder') }} </small>
            </a>
          </KeywordSelector>
        </template>
        <template v-slot:after-input>
          <b>{{ `${$tc('preview')}: ` }}</b>
          <span style="text-transform: uppercase" class="my-4">
            {{ resolveKeywords(formData.categoryPrefix) }}
          </span>
        </template>
      </FlotoFormItem>
      <FlotoFormItem
        id="access-permission"
        :label="`${$tc('access_permission')}`"
        rules="required"
      >
        <FlotoDropdownPicker
          v-model="formData.readPermission"
          as-input
          :options="serviceCatelogPermissionMap"
        />
      </FlotoFormItem>
      <FlotoFormItem
        v-if="formData.readPermission === 'requester_group'"
        id="requester-groups"
        :label="`${$tc('group')} ${$tc('access_level')}`"
        rules="required"
      >
        <FlotoRequesterGroupPicker
          v-model="formData.requesterGroupIds"
          multiple
          as-input
        />
      </FlotoFormItem>
      <FlotoFormItem
        v-if="enableMsp"
        id="company"
        :label="`${$tc('company')} ${$tc('access_level')}`"
      >
        <CompanyPicker
          v-model="formData.companyIds"
          multiple
          as-input
          @change="handleCompanyAccessLevelChange"
        />
      </FlotoFormItem>
      <CompanyWiseCategoryMapping
        v-if="enableMsp && (formData.companyIds || []).length > 0"
        v-model="formData.companyWisePrefix"
        :module-name="$constants.SERVICE_CATALOG"
        :available-company-category-keywords="availableKeywords"
        :allowed-company-ids="formData.companyIds"
      />
    </MCol>
  </MRow>
</template>
<script>
import Moment from 'moment'
import { MspConfigComputed } from '@state/modules/msp-config/helpers'
import KeywordSelector from '@components/email-keywords/keyword-selector'
import CompanyPicker from '@components/data-picker/company-picker'
import CompanyWiseCategoryMapping from '@src/modules/category/components/company-wise-category-mapping'
import { serviceCatelogPermissionMap } from '../utils/option-map'
export default {
  name: 'ServiceCategoryForm',
  components: { KeywordSelector, CompanyPicker, CompanyWiseCategoryMapping },
  model: {
    event: 'change',
  },
  props: {
    folder: { type: Object, required: true },
    resetForm: { type: Function, default: undefined },
    availableKeywords: { type: Object, default: undefined },
    moduleName: {
      type: String,
      default() {
        return this.$constants.SERVICE_CATALOG
      },
    },
  },
  data() {
    this.serviceCatelogPermissionMap = serviceCatelogPermissionMap()
    return {
      prefixCount: 0,
      preparedCompanyIds: [],
    }
  },
  computed: {
    ...MspConfigComputed,
    formData: {
      get() {
        return this.folder
      },
      set(data) {
        this.$event('change', { ...this.folder, ...data })
      },
    },
    validationRules() {
      const rules = {
        required: Boolean(this.formData.id),
        string_without_space: true,
      }
      if (this.prefixCount < 2) {
        rules.min_value = 2
      }
      if (this.prefixCount > 24) {
        rules.max_value = 24
      }
      return rules
    },
  },
  methods: {
    handlePrefixChange(value) {
      this.resetForm({
        ...this.formData,
        categoryPrefix: value.toUpperCase(),
      })
    },
    prefixKeywordSelectorHide() {
      let input = this.$el.querySelector('input#content')
      input.focus()
    },
    insertKeywordForCategoryPrefix(keyword) {
      const el = document.querySelector('input#content')
      let input = el
      const currentSelection = el.selectionStart
      const requestBody = `${(input.value || '').substr(
        0,
        currentSelection
      )}#$#${keyword.keyword}#$#${(input.value || '').substr(currentSelection)}`
      this.formData.categoryPrefix = requestBody.split('#$#').join('')
      this.$nextTick(() => {
        input.focus()
        input.selectionStart = currentSelection + keyword.keyword.length + 2
        input = null
      })
    },
    resolveKeywords(categoryPrefix) {
      categoryPrefix = (categoryPrefix || '').toUpperCase()
      if (categoryPrefix || ''.length > 0) {
        const keywordRegex = /({#).*?(#})/g
        const extractFormat = /(?<={#)(.*?)(?=#})/g
        const correction = {
          '#M#': '#MM#',
          '#D#': '#DD#',
        }
        Object.keys(correction).forEach((key) => {
          categoryPrefix = categoryPrefix.replaceAll(key, correction[key])
        })
        let addedKeywords = categoryPrefix.match(keywordRegex) || []
        let resolvedKeywords = []
        addedKeywords.forEach((keyword) => {
          let currentKeyword = keyword.match(extractFormat)
          if (currentKeyword[0]) {
            resolvedKeywords.push(Moment().format(currentKeyword[0]))
          } else {
            resolvedKeywords.push('')
          }
        })
        let previewText = categoryPrefix
        var i
        for (i = 0; i < addedKeywords.length; i++) {
          let newRegex = new RegExp(addedKeywords[i], 'g')
          previewText = previewText.replace(newRegex, resolvedKeywords[i])
        }
        this.prefixCount = previewText.length
        return previewText
      }
    },
    handleCompanyAccessLevelChange() {
      if ((this.formData.companyWisePrefix || []).length > 0) {
        this.formData.companyWisePrefix = []
      }
    },
  },
}
</script>
<style lang="less">
.category-prefix-style {
  input {
    text-transform: uppercase;
  }
}
</style>
