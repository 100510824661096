<template>
  <FlotoModule>
    <FormRulesProvider :module-name="$constants.TASK">
      <RouterView :key="$route.fullPath" />
    </FormRulesProvider>
  </FlotoModule>
</template>

<script>
import { PageMethods } from '@state/modules/page'
import { isLoggedIn, isCxoUser } from '@utils/auth'
import { LicenseComputed } from '@state/modules/license'
import { PreferenceComputed } from '@state/modules/preference'
import { CustomRulesMethods } from '@state/modules/custom-rules'
import FormRulesProvider from '@components/providers/form-rules-provider/form-rules-provider'
export default {
  name: 'MyTasksModule',
  components: { FormRulesProvider },
  page() {
    return {
      title: this.$t('my_tasks'),
    }
  },
  beforeRouteEnter(to, from, next) {
    // vm is this
    next((vm) => {
      if (vm.availableModulesInLicense.indexOf(vm.$constants.TASK) === -1) {
        return vm.$router.replace({ name: 'upgrade-plan' })
      }
      if (isLoggedIn() && isCxoUser()) {
        return vm.$router.replace({ name: '404' })
      }
      if (
        vm.availableModulesInLicense.indexOf(vm.$constants.PROJECT) >= 0 &&
        vm.myAllowedModules.indexOf(vm.$constants.PROJECT) >= 0
      ) {
        if (vm.projectFetchWorklogRules) {
          vm.projectFetchWorklogRules()
        }
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    // add any module level clean up logic here
    setTimeout(() => {
      if (this.taskPageReset) {
        this.taskPageReset()
      }
    }, 400)
    next()
  },
  computed: {
    ...LicenseComputed,
    ...PreferenceComputed,
  },
  methods: {
    ...PageMethods,
    ...CustomRulesMethods,
  },
}
</script>
