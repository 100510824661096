import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      breadcrumbKey(route, translator, pluralTranslator) {
        if (route.name.indexOf('ldap') >= 0 || route.name.indexOf('sso') >= 0) {
          return pluralTranslator('user')
        }
        return translator('support_channel')
      },
      nonLinked: true,
    },
    children: [
      {
        path: 'emails',
        name: `${routeNamePrefix}.email-server-config`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/email-server-config'
            )
          ),
        meta: {
          titleFn(_, translator, pluralTranslator) {
            return `${pluralTranslator('email_plural', 2)}`
          },
        },
      },
      {
        path: 'outgoing-email-config-create',
        name: `${routeNamePrefix}.outgoing-email-config-create`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './components/outgoing-email-config-create'
            )
          ),
        meta: {},
      },
      {
        path: 'outgoing-email-config-edit/:id',
        name: `${routeNamePrefix}.outgoing-email-config-edit`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "automation" */ './components/outgoing-email-config-edit'
            )
          ),
        meta: {},
      },
      {
        path: 'incoming-email-config-create',
        name: `${routeNamePrefix}.incoming-email-config-create`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './components/incoming-email-config-create'
            )
          ),
        meta: {},
      },
      {
        path: 'incoming-email-config-edit/:id',
        name: `${routeNamePrefix}.incoming-email-config-edit`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "automation" */ './components/incoming-email-config-edit'
            )
          ),
        meta: {},
      },
      {
        path: 'support-portal',
        name: `${routeNamePrefix}.support-portal`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/support-portal'
            )
          ),
        meta: {
          titleFn(_, translator) {
            return `${translator('support_portal')}`
          },
        },
      },
      {
        path: 'ldap-configurations',
        name: `${routeNamePrefix}.ldap-configurations`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/ldap-configurations-list'
            )
          ),
        meta: {
          titleFn(_, translator) {
            return `${translator('ldap_configurations')}`
          },
        },
      },
      {
        path: 'sso-configuration',
        name: `${routeNamePrefix}.sso-configuration`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/sso-config/sso-configuration'
            )
          ),
        meta: {
          titleFn(_, translator) {
            return `${translator('sso_configuration')}`
          },
        },
      },
      {
        path: 'custom-sso-configuration',
        name: `${routeNamePrefix}.custom-sso-configuration`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/custom-sso-config/custom-sso-configuration'
            )
          ),
        meta: {
          titleFn(_, translator) {
            return `${translator('custom_sso_configuration')}`
          },
        },
      },
      {
        path: 'chat',
        name: `${routeNamePrefix}.chat`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "organization" */ './views/chat')
          ),
        meta: {
          titleFn(_, translator) {
            return `${translator('chat')}`
          },
        },
      },
      {
        path: 'ai-bot',
        name: `${routeNamePrefix}.ai-bot`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/ai-bot/ai-bot'
            )
          ),
        meta: {
          titleFn(_, translator) {
            return `${translator('ai_bot')}`
          },
        },
      },
      {
        path: 'messaging-app',
        name: `${routeNamePrefix}.messaging-app`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "organization" */ './views/messaging-app'
            )
          ),
        meta: {
          titleFn(_, translator) {
            return `${translator('messaging_app')}`
          },
        },
      },
      {
        path: 'ai-bot/chat-flow/:id',
        name: `${routeNamePrefix}.aibot-chatflow-view`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "packages" */ './views/ai-bot/chat-flow/detail/detail'
            )
          ),
        meta: {},
      },
      // {
      //   path: 'dns-configurations',
      //   name: `${routeNamePrefix}.dns-config`,
      //   component: () =>
      //     lazyLoadView(
      //       import(
      //         /* webpackChunkName: "it-infrastructure" */ './views/dns-config'
      //       )
      //     ),
      //   meta: {
      //     titleFn(_, translator) {
      //       return `${translator('dns')} ${translator('configuration')}`
      //     },
      //   },
      // },
    ],
  },
]
