import lazyLoadView from '@router/lazy-loader'
import ContainerView from './views/main'
import configs from './config'
import Constants from '@constants'

const routePrefix = configs.routePrefix

const moduleName = configs.name

const routeNamePrefix = configs.routeNamePrefix

export default [
  {
    path: `/${routePrefix}`,
    component: ContainerView,
    meta: {
      moduleName,
      titleFn(route, translator, pluralTranslator) {
        const type = route.params.moduleName || route.query.type
        const management =
          type === Constants.ASSET_MOVEMENT ? Constants.ASSET : type
        return `${translator(management)} ${translator('management')}`
      },
      nonLinked: true,
    },
    children: [
      {
        path: '',
        name: `${routeNamePrefix}`,
        component: () =>
          lazyLoadView(
            import(
              /* webpackChunkName: "calendar" */ './views/print-template-list'
            )
          ),
        meta: {
          breadcrumbKey(route, translator, pluralTranslator) {
            return `${pluralTranslator('print')} ${pluralTranslator(
              'template'
            )}`
          },
        },
      },
      {
        path: 'create',
        name: `${routeNamePrefix}.create`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "automation" */ './views/create')
          ),
        meta: {},
      },
      {
        path: 'edit/:moduleName/:id',
        name: `${routeNamePrefix}.edit`,
        component: () =>
          lazyLoadView(
            import(/* webpackChunkName: "automation" */ './views/edit')
          ),
        meta: {},
      },
    ],
  },
]
