import api from '@api'
import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
  transformDecisionAvailableQualification,
  transformAvailableQualification,
} from '@data/qualification'
import {
  transformChatFlow,
  transformChatFlowForServer,
  transformSlackAppConfig,
  transformSlackAppConfigForServer,
  transformTeamsAppConfig,
  transformTeamsAppConfigForServer,
  transformMessagingAppWhatsappConfig,
  transformMessagingAppWhatsappConfigForServer,
  transformTelegramAppConfig,
  transformTelegramAppConfigForServer,
  transformMessengerAppConfig,
  transformMessengerAppConfigForServer,
  transformBrain,
  transformBrainForServer,
  transformVariable,
  transformVariableForServer,
  transformWhatsappConfig,
  transformWhatsappConfigForServer,
  transformMessengerAppConfigSettings,
  transformViberAppConfig,
  transformViberAppConfigForServer,
  transformLineAppConfig,
  transformLineAppConfigForServer,
} from '@data/ai-bot'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.filterType) {
    quals.push(
      buildRelationalQualificationStructure(
        'applicableChatTypes',
        'any_member_or_all_members_exist',
        [qualificationFactors.filterType],
        'listEnum',
        'db'
      )
    )
  }
  if ('nonLoginFlow' in qualificationFactors) {
    quals.push(
      buildRelationalQualificationStructure(
        'nonLoginFlow',
        'equal',
        qualificationFactors.nonLoginFlow,
        'boolean',
        'db'
      )
    )
  }
  if (
    qualificationFactors.excludedIds &&
    qualificationFactors.excludedIds.length
  ) {
    quals.push(
      buildRelationalQualificationStructure(
        'id',
        'not_in',
        qualificationFactors.excludedIds,
        'long',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}
export function getChatFlowApi(filter = {}, limit, offset) {
  return api
    .post(
      'chat/flow/byqual',
      {
        qualDetails:
          filter.name ||
          filter.filterType ||
          (filter.excludedIds || []).length ||
          'nonLoginFlow' in filter
            ? buildQualifications(filter)
            : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformChatFlow) || [],
        total: data.totalCount,
      }
    })
}

export function getChatFlowDetailApi(id) {
  return api.get(`chat/flow/${id}`).then(transformChatFlow)
}
export function createChatFlowApi(data) {
  return api
    .post('chat/flow', transformChatFlowForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('chat_flow'),
      }),
    })
    .then(transformChatFlow)
}
export function updateChatFlowApi(data) {
  return api
    .patch(`chat/flow/${data.id}`, transformChatFlowForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('chat_flow'),
      }),
    })
    .then(transformChatFlow)
}
export function deleteChatFlowApi(id) {
  return api.delete(`chat/flow/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('chat_flow'),
    }),
  })
}

export function getSlackAppConfigApi() {
  return api.get(`/slackconfig`).then(transformSlackAppConfig)
}

export function updateSlackAppConfigApi(data) {
  return api
    .patch(`/slackconfig`, transformSlackAppConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('slack_app_config'),
      }),
    })
    .then(transformSlackAppConfig)
}

export function getViberAppConfigApi() {
  return api.get(`/viber/config`).then(transformViberAppConfig)
}

export function updateViberAppConfigApi(data) {
  return api
    .patch(`/viber/config`, transformViberAppConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('viber_config'),
      }),
    })
    .then(transformViberAppConfig)
}

export function getTelegramAppConfigApi() {
  return api.get(`/telegram/config`).then(transformTelegramAppConfig)
}

export function updateTelegramAppConfigApi(data) {
  return api
    .patch(`/telegram/config`, transformTelegramAppConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('telegram_app_config'),
      }),
    })
    .then(transformTelegramAppConfig)
}

export function getLineAppConfigApi() {
  return api.get(`/line/config`).then(transformLineAppConfig)
}

export function updateLineAppConfigApi(data) {
  return api
    .patch(`/line/config`, transformLineAppConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('line_app_config'),
      }),
    })
    .then(transformLineAppConfig)
}

export function getMessengerAppConfigApi(limit, offset, params) {
  return api
    .post(
      `/messenger/config/search/byqual`,
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformMessengerAppConfig) || [],
        total: data.totalCount,
      }
    })
}
export function createMessengerAppConfigApi(data) {
  return api
    .post(`/messenger/config`, transformMessengerAppConfigForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('facebook_messenger_app_config'),
      }),
    })
    .then(transformMessengerAppConfig)
}

export function updateMessengerAppConfigApi(data) {
  return api
    .patch(
      `/messenger/config/${data.id}`,
      transformMessengerAppConfigForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('facebook_messenger_app_config'),
        }),
      }
    )
    .then(transformMessengerAppConfig)
}

export function deleteMessengerAppConfigApi(id) {
  return api.delete(`/messenger/config/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('facebook_messenger_app_config'),
    }),
  })
}

export function getMessengerAppConfigSettingsApi() {
  return api.get('/messenger/setting').then(transformMessengerAppConfigSettings)
}

export function updateMessengerAppConfigSettingsApi(data) {
  return api
    .patch(`/messenger/setting`, data, {
      message: __rootT('updated_successfully', {
        resource: __rootTc('messenger_app_config'),
      }),
    })
    .then(transformMessengerAppConfigSettings)
}

export function getTeamsAppConfigApi() {
  return api.get(`/teamsconfig`).then(transformTeamsAppConfig)
}

export function updateTeamsAppConfigApi(data) {
  return api
    .patch(`/teamsconfig`, transformTeamsAppConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('teams_app_config'),
      }),
    })
    .then(transformTeamsAppConfig)
}

// Messaging App - Whatsapp Configuration Apis

export function getMessagingAppWatsappConfigApi() {
  return api
    .get(`/whatsapp/request/config`)
    .then(transformMessagingAppWhatsappConfig)
}

export function updateMessagingAppWhatsappConfigApi(data) {
  return api
    .patch(
      `/whatsapp/request/config`,
      transformMessagingAppWhatsappConfigForServer(data),
      {
        message: __rootT('updated_successfully', {
          resource: __rootTc('whatsapp_config'),
        }),
      }
    )
    .then(transformMessagingAppWhatsappConfig)
}

// Virtual-Agent - Whatsapp Configuration Crud Apis

export function getWatsappConfigApi() {
  return api.get(`/whatsapp/config`).then(transformWhatsappConfig)
}

export function updateWhatsappConfigApi(data) {
  return api
    .patch(`/whatsapp/config`, transformWhatsappConfigForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('whatsapp_config'),
      }),
    })
    .then(transformWhatsappConfig)
}

export function searchBrainsApi(flowId, qualifications, limit, offset) {
  return api
    .post(
      `${flowId}/chat/brain/byqual`,
      {
        ...(qualifications ? { qualDetails: qualifications } : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformBrain) || [],
        total: data.totalCount,
      }
    })
}

export function createBrainApi(flowId, data) {
  return api
    .post(`${flowId}/chat/brain`, transformBrainForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('node'),
      }),
    })
    .then((d) => transformBrain({ ...d, guid: data.guid }))
}
export function updateBrainApi(data) {
  return api
    .patch(`chat/brain/${data.id}`, transformBrainForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('node'),
      }),
    })
    .then(transformBrain)
}

export function deleteBrainApi(id) {
  return api.delete(`chat/brain/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('node'),
    }),
  })
}

export function getUtilityActionSupportedActionDataApi(
  moduleName,
  params = {}
) {
  return api
    .get(`chatbot/${moduleName}/supportedData/action`, {
      params: {
        ...params,
      },
    })
    .then((data) => {
      return {
        actionTypes: data.typeList,
        mappingField: data.mappingField,
      }
    })
}
export function getUtilityPluginActionSupportedDataApi(pluginIdentifire) {
  return api
    .get(`/chatbot/supportedData/plugins`, {
      params: {
        name: pluginIdentifire,
      },
    })
    .then((data) => {
      return {
        actionTypes: data.typeList,
        mappingField: data.mappingField,
        outputMappingField: data.outputMappingField,
      }
    })
}
export function getUtilityActionSupportedValueDataApi(id) {
  return api.get(`chatbot/${id}/suppportedData`).then((data) => {
    return (data.objectList || []).map((i) => ({
      ...i,
      key: i.propKey,
      text: i.name,
    }))
  })
}

export function getVariableApi(limit, offset) {
  return api
    .post(
      `/global/variable/byqual`,
      {},
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformVariable),
        total: data.totalCount,
      }
    })
}

export function createVariableApi(data) {
  return api
    .post('/global/variable', transformVariableForServer(data), {
      message: __rootT('created_successfully', {
        resource: __rootTc('variable'),
      }),
    })
    .then(transformVariable)
}
export function updateVariableApi(data) {
  return api
    .patch(`/global/variable/${data.id}`, transformVariableForServer(data), {
      message: __rootT('updated_successfully', {
        resource: __rootTc('variable'),
      }),
    })
    .then(transformVariable)
}
export function deleteVariableApi(id) {
  return api.delete(`/global/variable/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootTc('variable'),
    }),
  })
}

export function getTrainModelApi() {
  return api.get(`/chatbot/trainnlu`, {
    notification: {
      message: __rootT('success'),
      description: __rootT('training_data', {}),
    },
  })
}
// get supported custom field list base on model and service catalog id for user ref choice creation
export function getSupportedCustomFieldsApi(moduleName, params = {}) {
  return api
    .get(`chatbot/${moduleName}/supportedData/customfield`, {
      params: {
        ...params,
      },
    })
    .then((data) => {
      return (data.objectList || []).map((i) => ({ key: i.id, text: i.value }))
    })
}

export function getSupportedDecisionQualApi(id) {
  return api.get(`/chatbot/${id}/suppportedData`).then((data) => {
    return {
      qualifications: (data.objectList || []).map(
        transformDecisionAvailableQualification
      ),
    }
  })
}

export function getSupportedSearchQualApi(moduleName, params = {}) {
  return api
    .get(`chatbot/${moduleName}/supportedData/search`, {
      params: {
        ...params,
      },
    })
    .then((data) => {
      return {
        qualifications: (data.qualPropList || []).map(
          transformAvailableQualification
        ),
      }
    })
}
