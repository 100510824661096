import api from '@api'

import {
  transformPrintTemplate,
  transformPrintTemplateForServer,
} from '@data/print-template'
import {
  buildFlatQualificationStructure,
  buildRelationalQualificationStructure,
} from '@data/qualification'
import {
  getRootPluaralTranslator,
  getRootTranslator,
} from '@utils/get-module-translator'

const __rootT = getRootTranslator()
const __rootTc = getRootPluaralTranslator()

export function getPrintTemplatesApi(
  moduleName,
  filter,
  searchCriteria,
  limit,
  offset,
  sortedColumn,
  additionalParams
) {
  let qualDetails = [...((searchCriteria || {}).quals || [])]
  if ('name' in filter) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        filter.name,
        'string',
        'db'
      )
    )
  }
  if ('assetTypeId' in filter) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'assetTypeId',
        'equal',
        filter.assetTypeId,
        'integer',
        'db'
      )
    )
  }
  if ('enabled' in filter) {
    qualDetails.push(
      buildRelationalQualificationStructure(
        'enabled',
        'equal',
        true,
        'boolean',
        'db'
      )
    )
  }
  return api
    .post(
      `/${moduleName}/print/template/search/byqual`,
      {
        qualDetails: qualDetails.length
          ? buildFlatQualificationStructure(qualDetails)
          : undefined,
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...(sortedColumn ? { sort_by: sortedColumn } : {}),
          ...additionalParams,
        },
        notification: {
          error: {
            message: __rootT('error'),
          },
        },
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformPrintTemplate),
        total: data.totalCount,
      }
    })
}

export function createPrintTemplateApi(moduleName, template, additionalParams) {
  return api
    .post(
      `/${moduleName}/print/template`,
      transformPrintTemplateForServer(template),
      {
        params: {
          ...additionalParams,
        },
        message: __rootT('created_successfully', {
          resource: `${__rootTc('print')} ${__rootTc('template')}`,
        }),
      }
    )
    .then(transformPrintTemplate)
}

export function getPrintTemplateApi(id) {
  return api.get(`/print/template/${id}`).then(transformPrintTemplate)
}

export function deletePrintTemplateApi(id) {
  return api.delete(`/print/template/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: `${__rootTc('print')} ${__rootTc('template')}`,
    }),
  })
}

export function updatePrintTemplateApi(template) {
  return api
    .patch(
      `/print/template/${template.id}`,
      transformPrintTemplateForServer(template),
      {
        message: __rootT('updated_successfully', {
          resource: `${__rootTc('print')} ${__rootTc('template')}`,
        }),
      }
    )
    .then(transformPrintTemplate)
}

export function toggleEnableApi(data) {
  return api
    .patch(`/print/template/${data.id}`, data, {
      message: __rootT('updated_successfully', {
        resource: `${__rootTc('print')} ${__rootTc('template')}`,
      }),
    })
    .then(transformPrintTemplate)
}

export function getServiceCatalogPrintTemplateApi(moduleName, id) {
  return api.get(`/download/print/template/${moduleName}/${id}`)
}
